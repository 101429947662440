@import "../../common.scss";

.notfound_sec1 {
  height: 100vh;
  overflow: hidden;
  padding-top: 7.5em;
  .notfound_row {
    margin: 0 auto;
  }
  .notfound_col {
    padding: 0;
    align-self: center;
    &:nth-child(2n + 1) .notfound_img_wrapper {
      padding-right: 6.875em;
    }
    &:nth-child(2n + 2) .notfound_content_wrapper {
      margin-left: -0.1875em;
      //   padding-right: 8.6875em;
      padding-right: 8.5em;
    }
  }
  .notfound_img_wrapper {
    width: 100%;
    // max-width: 63.125em;
    max-width: 100%;
    height: calc(100vh - 7.5em);
    .notfound_img {
      width: 100%;
      max-width: 100%;
      height: 100%;
    }
  }
  .notfound_content_wrapper {
    .notfound_heading {
      color: #1c1c1c;
      font-size: 18.75em;
      font-weight: bold;
      font-family: "Josefin Sans";
      letter-spacing: 0px;
      //   line-height: 1.2;
      line-height: 1;
      text-align: center;
    }
    .notfound_desc {
      color: #1c1c1c;
      font-size: 1.375em;
      font-weight: bold;
      font-family: "Montserrat";
      letter-spacing: 1.1px;
      line-height: 1.364;
      text-transform: uppercase;
      text-align: center;
      margin: 0 0 2.727272727272727em;
    }
    .notfound_cta_wrapper {
      .knowmore_sec1 {
        max-width: 19.1875em;
        margin: 0 auto;
      }
    }
  }
}

@media (max-width: 1194px) {
  .notfound_sec1 {
    .notfound_col {
      &:nth-child(2n + 1) .notfound_img_wrapper {
        padding-right: 2.75em;
      }
      &:nth-child(2n + 2) .notfound_content_wrapper {
        padding-left: 0;
        padding-right: 2.25em;
      }
    }
    .notfound_content_wrapper {
      .thakyou_desc {
        padding: 0 2em;
      }
    }
  }
}

@media (max-width: 991px) {
  .notfound_sec1 {
    padding: 7.5em 0 5em;
    height: 100%;
    .notfound_col {
      &:nth-child(2n + 1) {
        .notfound_img_wrapper {
          padding-right: 0;
        }
      }
      &:nth-child(2n + 2) {
        .notfound_content_wrapper {
          padding-right: 0;
        }
      }
    }
    .notfound_img_wrapper {
      height: 100%;
    }
    .notfound_content_wrapper {
      margin-top: 5em;
    }
  }
}

@media (max-width: 767px) {
  .notfound_sec1 {
    padding: 5.5em 0 9.5em;
    // height: 100vh;
    .notfound_content_wrapper {
      margin-top: 3.1em;
      .notfound_heading {
        font-size: 14em;
        // line-height: 0.542;
        line-height: normal;
      }
      .notfound_desc {
        font-size: 1.8em;
        letter-spacing: 0.9px;
        line-height: 1.555;
        margin: 1.666666666666667em 0 1.666666666666667em;
        padding: 0 1.527777777777778em;
      }
      .notfound_content_wrapper {
        .notfound_cta_wrapper {
          .knowmore_sec1 {
            max-width: 18.7em;
          }
        }
      }
    }
  }
}

// @media (max-width: 767px) and (max-height: 800px) {
//   .notfound_sec1 {
//     overflow: auto;
//   }
// }
