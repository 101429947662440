@import "../../common.scss";

.thankyou_sec1 {
  height: 100vh;
  overflow: hidden;
  padding-top: 7.5em;
  .thankyou_row {
    margin: 0 auto;
  }
  .thankyou_col {
    padding: 0;
    align-self: center;
    &:nth-child(2n + 1) .thankyou_img_wrapper {
      padding-right: 6.875em;
    }
    &:nth-child(2n + 2) .thankyou_content_wrapper {
      padding-left: 1.0625em;
      padding-right: 7.9375em;
    }
  }
  .thankyou_img_wrapper {
    position: relative;
    width: 100%;
    // max-width: 63.125em;
    max-width: 100%;
    height: calc(100vh - 7.5em);
    .thankyou_overlay {
      width: 100%;
      max-width: calc(100% - 6.875em);
      background-color: #144c4b;
      opacity: 0.5;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
    .thanyou_img {
      width: 100%;
      max-width: 100%;
      height: 100%;
    }
  }
  .thankyou_content_wrapper {
    .thankyou_heading {
      color: #1c1c1c;
      font-size: 11.25em;
      font-weight: bold;
      font-family: "Josefin Sans";
      letter-spacing: 0px;
      line-height: 0.977;
      text-align: center;
    }
    .thakyou_desc {
      color: #1b1b1b;
      font-size: 1.375em;
      font-weight: bold;
      font-family: "Montserrat";
      letter-spacing: 1.1px;
      line-height: 1.4545;
      text-transform: uppercase;
      text-align: center;
      margin: 1em 0 2.727272727272727em;
      padding: 0 4em;
    }
    .thankyou_cta_wrapper {
      .knowmore_sec1 {
        max-width: 19.1875em;
        margin: 0 auto;
      }
    }
  }
}

@media (max-width: 1194px) {
  .thankyou_sec1 {
    .thankyou_col {
      &:nth-child(2n + 1) .thankyou_img_wrapper {
        padding-right: 2.75em;
      }
      &:nth-child(2n + 2) .thankyou_content_wrapper {
        padding-left: 0;
        padding-right: 2.25em;
      }
    }
    .thankyou_content_wrapper {
      .thakyou_desc {
        padding: 0 2em;
      }
    }
    .thankyou_img_wrapper {
      .thankyou_overlay {
        max-width: calc(100% - 2.75em);
      }
    }
  }
}

@media (max-width: 991px) {
  .thankyou_sec1 {
    padding: 7.5em 0 5em;
    height: 100%;
    .thankyou_col {
      &:nth-child(2n + 1) {
        .thankyou_img_wrapper {
          padding-right: 0;
        }
      }
      &:nth-child(2n + 2) {
        .thankyou_content_wrapper {
          padding-right: 0;
        }
      }
    }
    .thankyou_img_wrapper {
      height: 100%;
      .thankyou_overlay {
        max-width: 100%;
      }
    }
    .thankyou_content_wrapper {
      margin-top: 5em;
    }
  }
}

@media (max-width: 767px) {
  .thankyou_sec1 {
    padding: 5.5em 0 4.6em;
    .thankyou_content_wrapper {
      margin-top: 3.2em;
      .thankyou_heading {
        font-size: 7em;
        line-height: 1.086;
      }
      .thakyou_desc {
        font-size: 1.8em;
        letter-spacing: 0.9px;
        line-height: 1.555;
        margin: 1.666666666666667em 0 1.666666666666667em;
        padding: 0 2em;
      }
      .thankyou_content_wrapper {
        .thankyou_cta_wrapper {
          .knowmore_sec1 {
            max-width: 18.7em;
          }
        }
      }
    }
  }
}
