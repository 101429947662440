@import "../../common.scss";

.home_sec1 {
  position: relative;
  z-index: 1;
  .home_slider_wrapper {
    position: relative;
  }
  .banner_img_wrapper {
    display: flex;
    align-items: center;
    // position: absolute;
    // top: 0;
    // left: 0;
    width: 100%;
    max-width: 100%;
    height: 100vh;
    // z-index: -1;
    overflow: hidden;
    .banner_image {
      width: 100%;
      max-width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
  .banner_content_wrapper {
    position: absolute;
    left: inherit;
    // top: 14.25em;
    top: 12.25em;
    .banner_heading {
      color: #ffffff;
      font-size: 5.75em;
      font-weight: bold;
      font-family: "Josefin Sans";
      letter-spacing: 0px;
      line-height: 1.195;
      text-transform: capitalize;
    }
    .cta_wrapper {
      margin-top: 5em;
    }
  }
  .home_arrow {
    width: 100%;
    max-width: 17.25em;
    background-color: transparent;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2.5em;
    position: absolute;
    left: inherit;
    bottom: 36.5%;
    .home_prev,
    .home_next {
      display: block;
      width: 100%;
      max-width: 4.375em;
      height: 4.375em;
      object-fit: contain;
      cursor: pointer;
      z-index: 1;
    }
  }
}

.home_sec2 {
  position: relative;
  margin-top: -16.75%;
  // z-index: 1;
  .background {
    background-color: #f3f3f3;
    width: 100%;
    max-width: 28.73%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }
  .coating_wrapper {
    position: relative;
    z-index: 2;
  }
  .coating_heading {
    @include subhead;
    color: #f3f3f3;
    margin-bottom: 0.8333333333333333em;
  }
  .coating_content_wrapper {
    position: relative;
    .coating_back_img {
      width: 100%;
      //   max-width: 51.25em;
      max-width: 50%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
    }
  }
  .coating_details_container {
    //     background-image: url("../../source/images/home/coating_services.png");
    margin: 0 auto;
  }
  .coating_col {
    &:nth-child(4) {
      .coating_box {
        background-color: #144c4b;
      }
    }
    &:nth-child(6) {
      .coating_box {
        background-color: #30a389;
      }
    }
    &:nth-child(7) {
      .coating_box {
        background-color: #1c1c1c;
      }
    }
    &:nth-child(8) {
      .coating_box {
        background-color: #f3f3f3;
      }
    }
  }
  .coating_box {
    width: 100%;
    // max-width: 25.625em;
    max-width: 100%;
    background-color: #ffffff;
    height: 100%;
    min-height: 24.25em;
    max-height: 24.25em;
    padding: 2em 2em 2.9133em 2em;
    .coating_img {
      width: 100%;
      max-width: 3em;
    }
    .coating_title {
      @include subhead;
      color: #1c1c1c;
      margin: 1.333333333333333em 0;
    }
    .white_title {
      color: #ffffff;
    }
    .coating_desc {
      @include desc;
      color: #1c1c1c;
    }
    .white_desc {
      color: #ffffff;
    }
  }
}

.home_sec3 {
  position: relative;
  padding: 9.375em 0;
  .background {
    background-color: #f3f3f3;
    width: 100%;
    max-width: 28.73%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }
  .about_col {
    align-self: center;
  }
  .about_slider_wrapper {
    width: 100%;
    max-width: 100%;
    height: 100%;
    position: relative;
  }
  .about_slider_img {
    width: 100%;
    max-width: 100%;
    height: 100%;
  }
  .about_arrow {
    width: 100%;
    max-width: 17.25em;
    background-color: #144c4b;
    padding: 1.5625em 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2.5em;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    .about_prev,
    .about_next {
      display: block;
      width: 100%;
      max-width: 4.375em;
      height: 4.375em;
      object-fit: contain;
      cursor: pointer;
    }
  }
  .about_content_wrapper {
    padding: 0 3em 0 9.3125em;
  }
  .heading {
    @include head;
    color: #1c1c1c;
  }
  .subheading {
    @include subtitle;
    color: #1c1c1c;
    margin: 0.8461538461538462em 0 1.461538461538462em;
  }
  .desc {
    @include desc;
    color: #1c1c1c;
    padding-right: 5em;
  }
  .ticker_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 7.9375em;
    text-align: center;
    margin: 4.3125em 0 5em;
    .ticker_details {
      position: relative;
      width: 100%;
      //   max-width: 50%;
      max-width: fit-content;
      &:nth-child(2) .ticker_img {
        top: -2.5%;
        left: -45%;
      }
    }
    .ticker_img {
      position: absolute;
      top: -1%;
      left: 9%;
      width: 100%;
      max-width: 5.4375em;
    }
  }
}

.home_sec4 {
  position: relative;
  padding: 4.9375em 0 5.375em;
  // .parallax {
  background-image: url("../../source/images/home/our_recognitions_bg_image.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  // background-size: cover;
  width: 100%;
  max-width: 100%;
  // }
  .heading {
    @include head;
    color: #ffffff;
    text-align: center;
  }
  .desc {
    @include desc;
    color: #ffffff;
    font-weight: 500;
    text-align: center;
    padding: 1.222222222222222em 18em 3em;
  }
  .recognitions_img {
    width: 100%;
    max-width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }
  .recognitions_img_wrapper {
    display: flex;
    // justify-content: space-between;
    justify-content: center;
    align-items: center;
    gap: 8.625em;
  }
  .recog_img {
    width: 100%;
    max-width: 19.125em;
  }
}

.home_sec5 {
  padding: 9.5em 0 11.6875em;
  position: relative;
  .background {
    background-color: #f3f3f3;
    width: 100%;
    // max-width: 34.375em;
    max-width: 28.73%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
  .heading {
    @include head;
    color: #1c1c1c;
    // margin-bottom: 0.55em;
  }
  .product_row {
    margin-top: -1em;
  }
  .accordion-item {
    border: none;
    border-top: 1px solid #1c1c1c33;
    &:first-child {
      border: none;
      //   button {
      //     padding-top: 0;
      //   }
    }
  }
  .product_col {
    &:nth-child(2) {
      padding-right: 6.25em;
    }
    &:nth-child(1) {
      padding-left: 5.375em;
    }
  }
  .accordion-header {
    font-size: inherit;
    button {
      border: none;
      background-color: transparent;
      width: 100%;
      max-width: 100%;
      text-align: left;
      //   padding: 1.359375em 0;
      cursor: pointer;
    }
    .accordion-button {
      background: url("../../source/images/home/green_rightarrow.png") no-repeat;
      background-size: 2em;
      background-position: calc(100% - 0.1em) center;
      // padding: 1.359375em 0 1.28125em;
      padding: 2.71875em 0 2.5625em;
      border-radius: 0;
      &:focus {
        box-shadow: none;
      }
    }
    .accordion-button.collapsed {
      background: url("../../source/images/home/green_circle_rightarrow.png")
        no-repeat;
      background-size: 2em;
      // background-size: 1.5em;
      background-position: calc(100% - 0.1em) center;
      // padding: 1.359375em 0;
      padding: 2.71875em 0;
    }
    .accordion-button:not(.collapsed) {
      box-shadow: none;
    }
  }
  .accordion-button::after {
    display: none;
  }
  .accordion-body {
    padding: 0;
    border: none;
  }
  .accor_heading_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding-right: 3em;
    .heading {
      color: #144c4b;
      font-family: "Montserrat";
      font-size: 1.5em;
      font-weight: bold;
      letter-spacing: 0px;
      line-height: 1.208;
      margin: 0;
      padding-left: 0.8em;
    }
    .number {
      -webkit-text-fill-color: #1c1c1c1f;
      -webkit-text-stroke-width: 1px;
      // -webkit-text-stroke-color: #1c1c1c1f;
      -webkit-text-stroke-color: rgb(219 218 218 / 12%);
      background-clip: text;
      -webkit-background-clip: text;
      background-position: 100%;
      background-size: 200% 100%;
      font-size: 2.083333333333333em;
      font-weight: bold;
      font-family: "Josefin Sans";
      letter-spacing: 0px;
      line-height: 1.22;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .accor_desc_wrapper {
    @include desc;
    padding: 0 6em 1.777777777777778em 0;
    .product_link {
      color: inherit;
      text-decoration: underline;
      font-weight: 500;
      &:hover {
        font-weight: bold;
      }
    }
  }
  .product_img_wrapper {
    width: 100%;
    max-width: 100%;
    height: 100%;
    .product_img {
      width: 100%;
      max-width: 100%;
      height: 100%;
      transition: 0.3s ease;
      object-fit: contain;
    }
    .product_img_1 {
      max-width: 78.11%;
    }
  }
}

@media (max-width: 1920px) and (max-height: 1005px) {
  .home_sec1 {
    .home_arrow {
      bottom: 32.5%;
    }
  }
  .home_sec2 {
    margin-top: -14.75%;
  }
}

@media (max-width: 1700px) {
  .home_sec5 {
    // .accordion-header {}
    .accor_heading_wrapper {
      font-size: 13px;
    }
  }
}

@media (max-width: 1536px) {
  .home_sec5 {
    .accor_heading_wrapper {
      font-size: 11px;
    }
  }
}

@media (max-width: 1536px) and (max-height: 754px) {
  .home_sec1 {
    .home_arrow {
      bottom: 33.5%;
    }
  }
  .home_sec2 {
    margin-top: -10.75%;
  }
}

@media (max-width: 1366px) {
  .home_sec5 {
    .accor_heading_wrapper {
      font-size: 10px;
    }
  }
}

@media (max-width: 1194px) {
  .home_sec4 {
    .recognitions_img_wrapper {
      gap: 3em;
    }
  }
}

@media (max-width: 1024px) {
  .home_sec2 {
    .coating_box {
      min-height: 26.25em;
      max-height: 26.25em;
    }
  }
  .home_sec5 {
    .product_img_wrapper {
      .product_img_1 {
        max-width: 90%;
      }
    }
  }
}

@media (max-width: 1024px) and (min-height: 1081px) {
  .home_sec1 {
    .banner_img_wrapper {
      height: 72vh;
      // .banner_image {
      //   object-fit: cover;
      //   object-position: right;
      // }
    }
  }
}

// @media (max-width: 992px) {
//   .home_sec1 {
//     .banner_content_wrapper {
//       top: 36.25em;
//     }
//   }
// }

@media (max-width: 835px) {
  .home_sec1 {
    // height: 100vh;
    background-color: #144c4b;
    .home_slider_wrapper {
      height: 100vh;
    }
    .banner_img_wrapper {
      // height: 60vh;
      height: 65vh;
      .banner_image {
        object-position: right;
        object-fit: cover;
      }
    }
    .banner_content_wrapper {
      // background-color: #144c4b;
      // height: 40vh;
      height: fit-content;
      position: relative;
      left: initial;
      top: initial;
      margin: 7em 0 6em;
      .banner_heading {
        text-align: center;
      }
      .cta_wrapper {
        .knowmore_sec1 {
          margin: 0 auto;
        }
      }
    }
    .home_arrow {
      justify-content: space-between;
      top: 0;
      bottom: 33%;
      left: 0;
      right: 0;
      width: 100%;
      max-width: 100%;
      .home_prev {
        margin-left: 1.6em;
      }
      .home_next {
        margin-right: 1.6em;
      }
    }
  }

  .home_sec2 {
    margin-top: 0;
  }
  .mbhome_sec2 {
    .coating_content {
      background-color: #144c4b;
      text-align: center;
      padding: 0 6.75em 14.8em;
      .coating_heading {
        color: #ffffff;
        font-family: "Montserrat";
        font-size: 1.5em;
        font-weight: bold;
        letter-spacing: 0px;
        line-height: 1.208;
        margin: 0 0 1em;
      }
      .caoting_text {
        font-size: 1.125em;
        line-height: 1.55;
        letter-spacing: 0px;
        font-family: "Montserrat";
        font-weight: 400;
        color: #ffffff;
      }
    }
    .coating_main_img {
      width: 100%;
      max-width: 100%;
      margin-top: -15%;
      .mbcoating_img {
        width: 100%;
        max-width: 100%;
      }
    }
    .coating_slider_wrapper {
      position: relative;
      margin-top: -4em;
    }
    .coating_box {
      background-color: #ffffff;
      box-shadow: -2px 3px 6px rgba(0, 0, 0, 0.169);
      width: 100%;
      max-width: 80%;
      margin: 1em auto 2.4em;
      text-align: center;
      padding: 4em 2em 6em 2em;
      .coating_img {
        width: 100%;
        max-width: 4.8em;
      }
      .coating_title {
        font-size: 1.5em;
        line-height: 1.208;
        letter-spacing: 0px;
        font-family: "Montserrat";
        font-weight: bold;
        color: #1c1c1c;
        margin: 1.3333333333em 0;
      }
      .coating_desc {
        font-size: 1.125em;
        line-height: 1.55;
        letter-spacing: 0px;
        font-family: "Montserrat";
        font-weight: 400;
        color: #1c1c1c;
      }
    }
    .coating_arrow {
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2.5em;
      width: 100%;
      max-width: 100%;
      .coating_prev,
      .coating_next {
        display: block;
        width: 100%;
        max-width: 4.375em;
        height: 4.375em;
        object-fit: contain;
        cursor: pointer;
        z-index: 1;
      }
    }
  }

  .home_sec3 {
    padding: 6.1em 0 6em;
    .desc {
      padding-right: 0;
    }
    .about_content_wrapper {
      text-align: center;
      padding: 0;
      margin-top: 5em;
    }
    .ticker_container {
      justify-content: center;
      gap: 6em;
    }
    .cta_wrapper {
      .knowmore_sec1 {
        margin: 0 auto;
      }
    }
    .about_arrow {
      position: relative;
      left: initial;
      bottom: initial;
      background-color: transparent;
      padding: 2.4em 0 1em;
      max-width: 100%;
    }
  }

  .home_sec4 {
    .desc {
      padding: 1.2222222222em 0em 3em;
    }
    .recognitions_img_wrapper {
      flex-wrap: wrap;
      gap: 6em;
    }
    .recog_img {
      max-width: 45%;
    }
  }

  .home_sec5 {
    .heading {
      text-align: center;
    }
    .product_col {
      &:nth-child(2) {
        padding-right: 0;
      }
      &:nth-child(1) {
        padding-left: 0;
      }
    }
    .accor_desc_wrapper {
      padding: 0 0 1.7777777778em 0;
    }
    .product_img_wrapper {
      max-width: 50%;
      margin: 0 auto;
      padding: 5em 0 3em;
    }
    .mb_product_img_wrapper {
      width: 100%;
      max-width: 70.5%;
      margin: 0 auto;
      .product_img {
        width: 100%;
        max-width: 100%;
        margin-top: 3em;
      }
    }
  }
}

@media (max-width: 767px) {
  .home_sec1 {
    .banner_img_wrapper {
      height: 67.488vh;
    }
    .banner_content_wrapper {
      margin: 3.4em 0 3em;
      .banner_heading {
        font-size: 4em;
        line-height: 1.25;
      }
      .cta_wrapper {
        margin-top: 2.8em;
      }
    }
  }

  .mbhome_sec2 {
    .coating_content {
      padding: 3em 1.5em 14.8em;
      .coating_heading {
        font-size: 2em;
        line-height: 1.2;
        margin-bottom: 1.1em;
      }
      .caoting_text {
        font-size: 1.6em;
        line-height: 1.625;
        margin-bottom: 2em;
      }
    }
    .coating_main_img {
      max-width: 100%;
      margin-top: -42%;
    }
    .coating_slider_wrapper {
      margin-top: -4em;
    }
    .coating_box {
      max-width: 89.277%;
      padding: 3em 2em 3em 2.4em;
      .coating_img {
        max-width: 4em;
      }
      .coating_title {
        font-size: 2em;
        line-height: 1.2;
        margin-bottom: 1.1em;
      }
      .coating_desc {
        font-size: 1.6em;
        line-height: 1.625;
      }
    }
    .coating_arrow {
      gap: 1.6em;
    }
  }

  .home_sec3 {
    .heading {
      font-size: 3.2em;
      line-height: 1.1875em;
      margin-bottom: 0.6875em;
      text-align: center;
    }
    .subheading {
      font-size: 2em;
      line-height: 1.5;
      margin: 0 0 1.1em;
    }
    .desc {
      font-size: 1.6em;
      letter-spacing: 0.42px;
      line-height: 1.75;
      padding-right: 0;
    }
    .about_content_wrapper {
      margin-top: 3.2em;
    }
    .ticker_container {
      flex-direction: column;
      gap: 4.2em;
      margin: 3.2em 0 4em;
      .ticker_img {
        max-width: 5.164em;
        left: 15%;
      }
      .ticker_details {
        &:nth-child(2) {
          .ticker_img {
            max-width: 5.4em;
            left: -45%;
          }
        }
      }
    }
   .about_arrow {
    gap: 1.6em;
   }
  }

  .home_sec4 {
    padding: 4em 0 4em;
    .heading {
      font-size: 3.2em;
      line-height: 1.1875;
    }
    .desc {
      font-size: 1.6em;
      letter-spacing: 0.42px;
      line-height: 1.75;
      padding: 1.375em 0 2.125em;
    }
    .recognitions_img_wrapper {
      gap: 0;
    }
    .recog_img {
      max-width: 50%;
      // padding-bottom: 1.5em;
      padding-bottom: 4.35%;
      &:nth-child(2n + 1) {
        // padding-right: 0.75em;
        padding-right: 2.175%;
      }
      &:nth-child(2n + 2) {
        // padding-left: 0.75em;
        padding-left: 2.175%;
      }
      &:nth-last-child(1),
      &:nth-last-child(2) {
        padding-bottom: 0;
      }
    }
  }

  .home_sec5 {
    padding: 6.1em 0 6.1em;
    .heading {
      font-size: 3.2em;
      line-height: 1.1875;
    }
    .product_row {
      margin: 0 auto;
    }
    .product_col {
      padding: 0;
    }
    .accor_desc_wrapper {
      font-size: 1.6em;
      letter-spacing: 0.42px;
      line-height: 1.75;
      padding: 0 0 1.5em 0;
    }
    .accordion-header {
      .accordion-button {
        background-position: calc(100% - 0.2em) center;
      }
      .accordion-button.collapsed {
        background-position: calc(100% - 0.2em) center;
      }
    }
    .mb_product_img_wrapper {
      width: 100%;
      max-width: 70.5%;
      margin: 0 auto;
      .product_img {
        width: 100%;
        max-width: 100%;
        margin-top: 1.5em;
      }
    }
    .accor_heading_wrapper {
      .heading {
        font-size: 1.8em;
        line-height: 1.222;
        text-align: left;
      }
      .number {
        // font-size: 3.2em;
        // line-height: 1.1875;
        font-size: 2.0833333333em;
        line-height: 1.22;
      }
    }
    .accordion-header {
      .accordion-button.collapsed {
        padding: 1.4375em 0;
      }
      .accordion-button {
        padding: 1.4375em 0;
      }
    }
  }
}

@media (max-width: 767px) and (max-height: 670px) {
  .home_sec1 {
    .banner_img_wrapper {
      height: 61.488vh;
    }
    .banner_content_wrapper {
      margin: 2em 0 2em;
      .cta_wrapper {
        margin-top: 1.5em;
      }
    }
    .home_arrow {
      bottom: 39%;
    }
  }
}
