@import "../../common.scss";

.footer {
  background-color: #144c4b;
  padding: 0.813em 0 0.688em;
  position: fixed;
  bottom: 0;
  width: 100%;
  .box_1 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.898em;
    .call {
      display: flex;
      .call_icon {
        width: 100%;
        max-width: 1.599em;
        height: 100%;
        max-height: 100%;
        .call_img {
          width: 100%;
          max-width: 1.599em;
          height: 100%;
          max-height: 1.624em;
          vertical-align: -webkit-baseline-middle;
        }
      }
      .text {
        .number a {
          font-size: 1.5em;
          color: #ffffff;
          text-decoration: none;
          cursor: pointer;
          margin-left: 0.5em;
          font-family: "poppins";
          display: inline-block;
          width: 100%;
          max-width: 100%;
        }
      }
    }
    .line {
      .line_img {
        height: 100%;
        max-height: 2.688em;
      }
    }
    .mail {
      display: flex;
      .mail_icon {
        width: 100%;
        max-width: 1.746em;
        height: 100%;
        max-height: 100%;
        .mail_img {
          width: 100%;
          max-width: 1.746em;
          height: 100%;
          max-height: 1.103em;
          // margin-top: 0.2em;
          vertical-align: -webkit-baseline-middle;
        }
      }
      .text {
        .mail_id a {
          font-size: 1.5em;
          color: #ffffff;
          text-decoration: none;
          cursor: pointer;
          margin-left: 0.5em;
          font-family: "poppins";
          display: inline-block;
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
  .location {
    display: flex;
    justify-content: center;
    padding-top: 0.688em;
    .loaction_icon {
      width: 100%;
      max-width: 1.145em;
      text-decoration: none;
      .location_img {
        width: 100%;
        max-width: 1.145em;
        height: 100%;
        height: 1.53em;
        vertical-align: -webkit-baseline-middle;
      }
      a {
        text-decoration: none;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .text {
      text-decoration: none;
      a {
        text-decoration: none;
      }
      .location_text {
        color: #ffffff;
        margin-left: 0.5em;
        font-family: "poppins";
        font-size: 1.5em;
        text-decoration: none;
      }
    }
  }
}
@media (max-width: 1024px) {
  .footer {
    padding: 1.813em 2em 1.688em;
  }
}
@media (max-width: 995px) {
  .footer {
    padding: 3em 3em 3em 4em;
  }
}
@media (max-width: 835px) {
  .footer {
    // padding: 1.813em 2em 1.688em;
    .box_1 {
      gap: 1em;
    }
  }
}
@media (max-width: 600px) {
  .comingsoon_container {
    .column_1 {
      .main_text {
        font-size: 3.8em;
      }
    }
  }
  .footer {
    padding: 1.813em 2em 1.688em;
    .box_1 {
      justify-content: flex-start;
      gap: 0.898em;
    }
  }
}
@media (max-width: 400px) {
  .footer {
    .box_1 {
      gap: 0.5em;
      .call {
        .text {
          .number a {
            font-size: 1.4em;
            margin-left: 0.25em;
          }
        }
      }
      .mail {
        .text {
          .mail_id a {
            font-size: 1.4em;
            margin-left: 0.25em;
          }
        }
      }
    }
    .location {
      .text {
        .location_text {
          margin-left: 0.25em;
          font-size: 1.4em;
        }
      }
    }
  }
}

@media (max-width: 350px) {
  .comingsoon_container {
    .column_1 {
      .comingsoon_title {
        font-size: 1.7em;
      }
      .comingsoon_text {
        font-size: 2.2em;
      }
      .main_text {
        font-size: 3.2em;
      }
    }
  }
  .footer {
    // padding: 1.813em 1em 1.688em;
    .box_1 {
      gap: 0.5em;
      .call {
        .text {
          .number a {
            font-size: 1.25em;
          }
        }
      }
    }
  }
  .footer {
    .box_1 {
      gap: 0.5em;
      .call {
        .text {
          .number a {
            font-size: 1.2em;
          }
        }
      }
      .mail {
        .mail_icon {
          .mail_img {
            margin-top: -0.5em;
          }
        }
        .text {
          .mail_id a {
            font-size: 1.2em;
          }
        }
      }
    }
    .location {
      .text {
        .location_text {
          font-size: 1.2em;
        }
      }
    }
  }
}
