@import "./common-mixins.scss";

$color-ffffff: #ffffff;
$color-EE4E56: #ee4e56;
$color-F3F3F3: #f3f3f3;
$color-212529: #212529;
$color-707070: #707070;
$color-131313: #131313;
$color-9A9A9A: #9a9a9a;
$color-DDDDDD: #dddddd;
$color-FFAEB2: #ffaeb2;
$color-626262: #626262;

body {
  font-family: "Red Hat Display", sans-serif;
}
img {
  object-fit: cover;
  height: auto;
}
::-webkit-scrollbar {
  width: 6px;
  background-color: #545353;
}
::-webkit-scrollbar-thumb {
  background-color: $color-ffffff;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
figure {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}
:focus {
  outline: none;
  outline: -webkit-focus-ring-color auto 0px;
}

::-moz-focus-inner {
  border: 0;
}
button:focus {
  outline: 0px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}
li {
  list-style-type: none;
}
[type="submit"],
button,
html [type="button"] {
  appearance: none;
  -webkit-appearance: none;
}
input [type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  // -webkit-text-fill-color: $color-131313 !important;
  -webkit-text-fill-color: $color-ffffff !important;
}
.form_input {
  @include inputtextStyle;
  &::-webkit-input-placeholder {
    /* Edge */
    color: $color-626262;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $color-626262;
  }
  &::placeholder {
    color: $color-626262;
  }
}
.form_input:-webkit-autofill,
.form_input:-webkit-autofill:hover,
.form_input:-webkit-autofill:focus,
.form_input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
}

.form_field {
  margin-bottom: 5em;
  .label_head {
    @include headingStyle;
    font-size: 2.188em;
  }
  .radio_flex {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 2.5em;
    .radio_wrap {
      width: 100%;
      max-width: 25%;
      display: flex;
      align-items: center;
      margin-right: 8em;
      input[type="radio"] {
        @include inputRadioStyle;
      }
      input:checked {
        border: 0.5em solid $color-FFAEB2;
        background-color: $color-EE4E56;
        outline: unset !important; /* I added this one for Edge (chromium) support */
      }

      .radio_label {
        cursor: pointer;
        width: 100%;
        max-width: 100%;
        @include headingStyle;
        padding: 0 0 0 0.5em;
        margin: 0;
      }
    }
  }
}

.form_submit_btn {
  @include ctaStyle;
  &:hover {
    @include hoverStyle;
    .arrow_icon {
      right: 3em;
    }
  }
  .arrow_icon {
    @include arrowStyle;
  }
}
.common_cta {
  @include ctaStyle;
  text-align: left;
  padding-left: 1.781em;
  font-size: 1.25em;
  height: 3.25em;
  &:hover {
    @include hoverStyle;
    .arrow_icon {
      right: 1em;
    }
  }
  .arrow_icon {
    @include arrowStyle;
    max-width: 1.5em;
    right: 2em;
  }
}
table {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.error {
  // display: none;
  font-size: 1.125em;
  margin-top: 0.25em;
  font-weight: 600;
  color: red;
  position: absolute;
  &:first-letter {
    text-transform: uppercase;
  }
}
@media (min-width: 2550px) {
  body {
    font-size: 18px;
  }
  .my_container {
    max-width: 2226px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

// @media (min-width: 1921px) {
//   .my_container {
//     max-width: 2200px;
//     width: 100%;
//     margin: 0 auto;
//     padding: 0;
//   }
// }
@media (max-width: 1920px) {
  body {
    font-size: 16px;
  }
  .my_container {
    max-width: 1640px;
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
}

@media (max-width: 1700px) {
  body {
    font-size: 13px;
  }
  .my_container {
    max-width: 1619px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}
@media (max-width: 1620px) {
  body {
    font-size: 13px;
  }
  .my_container {
    max-width: 1480px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}
@media (max-width: 1536px) {
  body {
    font-size: 11px;
  }
  .my_container {
    max-width: 1356px;
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
}
@media (max-width: 1440px) {
  .my_container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
}

@media (max-width: 1366px) {
  body {
    font-size: 10px;
  }
  .my_container {
    max-width: 1194px;
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
}

@media (max-width: 1280px) {
  body {
    font-size: 10px;
  }
  .my_container {
    max-width: 1113px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1194px) {
  body {
    font-size: 10px;
  }
  .my_container {
    max-width: 1031px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}
@media (max-width: 1080px) {
  body {
    font-size: 10px;
  }
  .my_container {
    max-width: 931px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}
@media (max-width: 900px) {
  body {
    font-size: 10px;
  }
  .my_container {
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 600px) {
  .my_container {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}
