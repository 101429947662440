@import "../../common.scss";

.footer_sec1 {
  position: relative;
  padding: 4em 0 0;
  .scrollup_arrow {
    position: absolute;
    top: -11.5%;
    left: 50%;
    width: 100%;
    max-width: 3.75em;
    cursor: pointer;
  }
  .footer_wrapper {
    border-bottom: 2px solid #c8e7e0;
    padding-bottom: 2.1875em;
    padding-left: 7.75em;
  }

  .links_wrapper {
    .link_list {
      li {
        color: #1c1c1c;
        font-size: 1.125em;
        font-weight: 400;
        font-family: "Montserrat";
        letter-spacing: 0px;
        line-height: 1.222;
        margin-bottom: 1.611111111111111em;
        a {
          color: inherit;
        }
        .contact_link {
          margin: 0.25em 0;
          display: block;
        }
      }
    }
  }

  .footer_copyright_wrapper {
    padding: 2em 0;
    .copyright {
      color: #1c1c1c;
      font-size: 0.9375em;
      font-weight: 400;
      font-family: "Montserrat";
      letter-spacing: 0px;
      line-height: 1.267;
      text-align: center;
      .copyright_link {
        color: inherit;
      }
    }
  }
}

@media (max-width: 835px) {
  .footer_sec1 {
    .scrollup_arrow {
      left: 48%;
    }
  }
}

@media (max-width: 767px) {
  .footer_sec1 {
    padding: 3.2em 0 0;
    .scrollup_arrow {
      left: initial;
      right: 2.2%;
      top: -5%;
      max-width: 3.8em;
      cursor: pointer;
    }
    .footer_wrapper {
      padding-left: 0;
      padding-bottom: 0.8em;
    }
    .links_wrapper {
      text-align: center;
      .link_list {
        li {
          font-size: 1.4em;
          line-height: 1.285;
          margin-bottom: 1.428571428571429em;
          .contact_link {
            margin: 0.8571428571428571em 0;
          }
        }
      }
    }
    .footer_copyright_wrapper {
      padding: 2em 0;
      .copyright {
        font-size: 1.2em;
        line-height: 1.5;
        padding: 0 4em;
      }
    }
  }
}
