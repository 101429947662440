@import "../../common.scss";

.header_sec1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: transparent;
  z-index: 9;
  // padding: 2.875em 0;
  .navlinks {
    position: relative;
    .nav_list {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 5em;
      //   max-width: 50%;
      //   float: right;
      .logo_wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        .logo_img {
          position: relative;
          max-width: 17.048125em;
        }
      }
      .tab_wrapper {
        position: relative;
        padding: 2.9em 0;
        &:hover .dropdown_box {
          display: block;
        }
      }
      .nav_tab {
        position: relative;
        color: #ffffff;
        font-size: 1.375em;
        font-weight: 500;
        font-family: "Montserrat";
        letter-spacing: 0px;
        line-height: 1.227;
        white-space: nowrap;
        text-align: center;
        // margin: 0 1.818181818181818em 0 1.818181818181818em;
        // padding: 2em 0;
        transition: all 0.3s ease;
        cursor: pointer;
        //  &:nth-child(3) {
        //   margin-right: 0;
        //  }
        &::after {
          content: "";
          display: block;
          width: 0;
          height: 2px;
          background-color: #ffffff;
          left: 0;
          // bottom: -3px;
          bottom: -6px;
          position: absolute;
          // transform: scale(0);
          transition: width 0.3s;
        }
        &:hover {
          text-decoration: none;
          // font-weight: 600;
          transition: 0.3s ease;
          &::after {
            width: 100%;
            // transform: scale(1);
          }
        }
        .down_arrow {
          width: 100%;
          max-width: 0.538em;
          margin-left: 0.553635em;
        }
      }
      .nav_tab.active {
        font-weight: 500;
        &::after {
          width: 100%;
          // transform: scale(1);
        }
      }

      .nav_tab_dropdown {
        &::after {
          // display: none;
          width: 0;
        }
        &:hover {
          &::after {
            // width: 100%;
            width: 0;
          }
        }
      }

      .dropdown_box {
        // position: relative;
        position: absolute;
        // top: 200%;
        top: 65%;
        left: -10%;
        // height: fit-content;
        display: none;
        background-color: #ffffff;
        padding: 2.125em 2.1875em 3.4375em 2.1875em;
        width: 100%;
        // min-width: 17em;
        min-width: 24em;
        max-width: 100%;
        z-index: 1;
        li {
          &:nth-last-child(1) {
            .dropdown_items {
              margin-bottom: 0;
            }
          }
        }
        .dropdown_items {
          color: #1c1c1c;
          // background-color: #ffffff;
          font-size: 1.25em;
          font-weight: 500;
          font-family: "Montserrat";
          letter-spacing: 0px;
          line-height: 1.227;
          // white-space: nowrap;
          text-align: left;
          // width: 100%;
          // max-width: 100%;
          margin-bottom: 1em;
          display: inline-block;
          text-transform: capitalize;
          cursor: pointer;
          &:hover {
            color: #30a389;
          }
        }
      }
    }
  }
  &.navbar_bg {
    background-color: #ffffff;
    // border-bottom: 1px solid #e1e1e1;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.161);
    .navlinks {
      .nav_list {
        .nav_tab {
          color: #1c1c1c;
          &::after {
            background-color: #1c1c1c;
          }
        }
      }
    }
  }
}

.nav_line2_wrapper_flex_wrapper {
  position: fixed;
  top: 0;
  left: -100%;
  background-color: #ffffff;
  // background-color: #020202;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  transition: 0.3s left;
  &.active {
    left: 0;
  }
  .logo_wrapper {
    position: absolute;
    max-height: 10vh;
    // top: 1em;
    top: 1.2em;
    margin: auto;
    // left: -0.6em;
    left: 0;
    z-index: 9;
    .kapindra_logo {
      width: 100%;
      margin-left: 1.5em;
      max-width: 14.812em;
      padding: 2em 0;
    }
  }
  .nav_social_media_icons {
    position: absolute;
    bottom: 15%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: left;
    padding-left: 1.5em;

    .nav_social_icons {
      display: none;
      width: 100%;
      max-width: 2em;
      height: 2em;
      object-fit: cover;
      margin: 0 2em 0 0;
    }
    a:last-child {
      .nav_social_icons {
        margin-right: 0;
      }
    }
    .follow_us_text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      font-size: 1.6em;
      text-align: center;
      margin-top: 1.5em;
      a {
        color: #000000;
      }
    }
  }
}

.nav_line2_wrapper_flex {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  // height: 80%;
  height: 100%;
  overflow-y: auto;
  width: 100%;
  // margin: 10.1em 1.5em 0;
  margin: 5.8em 0 0;
  padding: 20.7em 1.5em 0;
  &::-webkit-scrollbar {
    width: 5px;
    background-color: #000000;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #000000;
  }
  .nav_item {
    text-align: left;
    width: 100%;
    position: relative;
    cursor: pointer;
    margin-left: 0;
    border-bottom: 1px solid #1c1c1c40;
  }
  .nav_link_wrapper {
    position: relative;
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin: 0 auto;
  }
  .nav_link {
    cursor: pointer;
    color: #1c1c1c;
    text-transform: capitalize;
    font-size: 1.8em;
    font-weight: 400;
    font-family: "Poppins";
    line-height: 3.888888888888889;
    opacity: 1;
    display: block;
    text-align: left;
    // border-bottom: 1px solid #1c1c1c40;
    // border-bottom: 1px solid #707070;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .not_active_arrow {
      display: block;
      width: 100%;
      max-width: 1.027em;
      object-fit: contain;
    }
    .active_arrow {
      display: none;
    }
  }
  .nav_link.active {
    position: relative;
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    color: #30a389;
    // border-bottom: 1px solid #1c1c1c40;

    // &::after {
    //   content: "";
    //   position: absolute;
    //   bottom: -0.4em;
    //   left: 0;
    //   background-color: #212529;
    //   height: 2.2px;
    //   width: 2em;
    // }
    .not_active_arrow {
      display: none;
    }
    .active_arrow {
      display: block;
      width: 100%;
      max-width: 1.027em;
      object-fit: contain;
    }
  }
  .nav_linkbtn {
    color: #ffffff;
    border: none;
    border-radius: 10px;
    background: transparent linear-gradient(90deg, #b20710 0%, #ee2c3c 100%) 0%
      0% no-repeat padding-box;
    padding: 0.75em 1.25em 0.75em 0.75em;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-width: 9em;
    font-weight: 600;
  }

  .dropdown_box {
    position: initial;
    width: 100%;
    text-align: left;
    background-color: transparent;
    height: 0 !important;
    transform: scaleY(0) !important;
    transition-duration: 0s;
    display: none;
  }
  .dropdown_box.active {
    height: auto !important;
    transform: scaleY(1) !important;
    display: block;
    // margin: 0 auto;
  }
  .dropdown_list_item {
    padding: 0;
    border: 0 !important;
    margin-bottom: 1.8em;
  }
  .dropdown_list_item_link {
    font-size: 1.6em;
    font-family: "Poppins";
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 4.375;
    line-height: normal;
    color: #1c1c1c;
    opacity: 1;
    // text-transform: capitalize;
    // &:hover {
    //   font-weight: bold;
    // }
  }
  // .dropdown_list_item_link.active {
  //   font-weight: bold;
  // }
}

.hamburger_lines {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1.5em;
  width: 2.4em;
  height: 1.6em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  .line,
  .line1,
  .line2,
  .line3 {
    height: 2px;
    width: 100%;
    border-radius: 1em;
    background-color: #ffffff;
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }
  // .line1 {
  //   max-width: 75%;
  //   -webkit-transition: 0.25s ease-in-out;
  //   -moz-transition: 0.25s ease-in-out;
  //   -o-transition: 0.25s ease-in-out;
  //   transition: 0.25s ease-in-out;
  // }
  // .line2 {
  //   -webkit-transition: 0.25s ease-in-out;
  //   -moz-transition: 0.25s ease-in-out;
  //   -o-transition: 0.25s ease-in-out;
  //   transition: 0.25s ease-in-out;
  // }
  // .line3 {
  //   max-width: 50%;
  //   -webkit-transition: 0.25s ease-in-out;
  //   -moz-transition: 0.25s ease-in-out;
  //   -o-transition: 0.25s ease-in-out;
  //   transition: 0.25s ease-in-out;
  // }
  &.active {
    top: 45%;
    .line {
      background-color: #000000;
    }
    .line1 {
      background-color: #30a389;
      max-width: 100%;
      top: 18px;
      -webkit-transform: rotate(135deg);
      -moz-transform: rotate(135deg);
      -o-transform: rotate(135deg);
      transform: translateY(1.1em) rotate(135deg);
    }

    .line2 {
      max-width: 100%;
      opacity: 0;
      left: -60px;
    }

    .line3 {
      background-color: #30a389;
      max-width: 100%;
      -webkit-transform: rotate(-135deg);
      -moz-transform: rotate(-135deg);
      -o-transform: rotate(-135deg);
      transform: translateY(-0.3em) rotate(-135deg);
    }
  }
  &.black {
    .line {
      background-color: #000000;
    }
  }
}

// @media (max-width: 1536px) {
//   .header_sec1 {
//     padding: 1.5em 0;
//     .navlinks {
//       ul {
//         .logo_wrapper {
//           top: 0;
//         }
//       }
//     }
//   }
// }

@media (max-width: 1120px) {
  .header_sec1 {
    .navlinks {
      .nav_list {
        .dropdown_box {
          min-width: 22em;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .header_sec1 {
    // padding: 1.2238em 0;
    padding: 1.7827em 0;
    .logo_wrapper {
      .kapindra_logo {
        width: 100%;
        max-width: 14.812em;
        margin-left: 0;
      }
    }
    .nav_line2_wrapper_flex_wrapper {
      .logo_wrapper {
        top: 1.8em;
        left: 1.6em;
        .kapindra_logo_1 {
          width: 100%;
          max-width: 14.812em;
        }
      }
    }
  }
}
