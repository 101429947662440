@import "../../common.scss";

.enquire_sec1 {
  background-color: #144c4b;
  padding: 5.0625em 0 5.03125em;
  .enquire_wrapper {
    .heading {
      color: #ffffff;
      font-size: 2.5em;
      font-family: "Josefin Sans";
      font-weight: bold;
      letter-spacing: 0px;
      line-height: 1.5;
    }
  }
  .form_col {
    &:nth-child(1) {
      .form-floating {
        max-width: 95%;
        margin-right: auto;
      }
    }
    &:nth-child(2) {
      .form-floating {
        max-width: 95%;
        margin: 0 auto;
      }
    }
    &:nth-child(3) {
      .form-floating {
        max-width: 95%;
        margin-left: auto;
      }
    }
    &:nth-child(4) {
      .form-floating {
        max-width: 97.5%;
        margin-right: auto;
        margin-top: 3.78125em;
      }
    }
  }
  .form_wrapper {
    .form_row {
      margin-top: 3.375em;
      //   margin-top: 2.625em;
    }
    .input_field {
      color: #ffffff;
      width: 100%;
      max-width: 100%;
      background-color: transparent;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #ffffff;
      font-size: 1.125em;
      font-family: "Montserrat";
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 2.5;
      height: fit-content;
      &:focus-visible {
        outline: none;
      }
      &:focus {
        box-shadow: none;
      }
      &::placeholder {
        color: transparent;
      }
      &:-webkit-autofill {
        -webkit-text-fill-color: #ffffff !important;
      }
    }
    .form-floating {
      width: 100%;
      max-width: 100%;
    }
    .form-floating > label {
      color: #ffffff;
      font-size: 1.125em;
      font-family: "Montserrat";
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 2.5;
      top: -10%;
      //   padding: 0 1em 1.139em;
      padding: 0 1em 0.75em;
    }
    .form-floating > .form-control {
      // padding: 0 1em 1.139em;
      padding: 0 1em 0.75em;
    }
    .form-floating > .form-control:focus ~ label,
    .form-floating > .form-control:not(:placeholder-shown) ~ label,
    .form-floating > .form-select ~ label {
      transform: scale(0.85) translateY(-1.5rem) translateX(0.15rem);
    }
    .btn_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      height: 100%;
      min-height: 100%;
      border-bottom: 1px solid #ffffff;
      padding-bottom: 0.75em;
      cursor: pointer;
      .submit_btn {
        background: transparent;
        border: none;
        color: #ffffff;
        font-size: 1.375em;
        font-family: "Montserrat";
        font-weight: 600;
        letter-spacing: 0px;
        line-height: 1.45;
        // border-bottom: 1px solid #ffffff;
        width: 100%;
        max-width: 100%;
        height: 100%;
        min-height: 100%;
        text-align: left;
        // padding-left: 1em;
        display: flex;
        // justify-content: space-between;
        align-items: flex-end;
      }
      .btn_img {
        width: 100%;
        max-width: 1.279375em;
        margin-bottom: 0.4em;
      }
    }
  }
}

@media (max-width: 835px) {
  .enquire_sec1 {
    .form_col {
      &:nth-child(1) {
        .form-floating {
          max-width: 100%;
          margin-right: 0;
        }
      }
      &:nth-child(2) {
        .form-floating {
          max-width: 100%;
          margin: 0;
          margin-top: 4em;
        }
      }
      &:nth-child(3) {
        .form-floating {
          max-width: 100%;
          margin-left: 0;
        }
      }
      &:nth-child(4) {
        .form-floating {
          max-width: 100%;
          margin-right: 0;
          margin-top: 4em;
        }
      }
    }
    .form_wrapper {
      .form-floating {
        margin-top: 4em;
      }
      .btn_wrapper {
        width: 100%;
        max-width: 7.866em;
        margin: 4em auto 0;
        height: auto;
        min-height: auto;
        gap: 1em;
        .submit_btn {
          padding-left: 0;
        }
      }
      .form-floating > .form-control {
        padding: 0 0 0.75em;
      }
      .form-floating > label {
        padding: 0 0 0.75em;
      }
    }
  }
}

@media (max-width: 767px) {
  .enquire_sec1 {
    padding: 3.2em 0;
    .enquire_wrapper {
      .heading {
        font-size: 3.2em;
        line-height: 1.1875;
        text-align: center;
      }
    }
    form_row {
      margin-top: 2.2em;
    }
    .form_col {
      &:nth-child(1) {
        .form-floating {
          margin-top: 0;
        }
      }
      &:nth-child(2) {
        .form-floating {
          margin-top: 4em;
        }
      }
      &:nth-child(4) {
        .form-floating {
          margin-top: 4em;
        }
      }
    }
    .form_wrapper {
      .form-floating {
        margin-top: 4em;
      }
      .input_wrapper {
        position: relative;
      }
      .input_field {
        font-size: 1.4em;
        // line-height: 3.214;
        line-height: normal;
        &:focus {
          font-size: 1.6em;
        }
      }
      .form-floating > label {
        font-size: 1.4em;
        line-height: normal;
      }
      .form-floating > .form-control {
        padding: 0 0 0.75em;
      }
      .btn_wrapper {
        margin: 4em auto 0;
        .submit_btn {
          font-size: 1.5em;
        }

        .btn_img {
          max-width: 1.4em;
        }
      }
    }
  }
}
