@import "../../common.scss";

.actual_count {
  color: #144c4b;
  font-size: 3.9375em;
  font-family: "Montserrat";
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 1.222;
}
.count_title {
  color: #1c1c1c;
  font-size: 1.5625em;
  font-family: "Montserrat";
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 1.56;
  margin-top: 1.08em;
}

@media (max-width: 767px) {
  .count_title {
    font-size: 2em;
    line-height: 1.95;
    margin-top: 0.85em;
  }
}
