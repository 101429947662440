@import "../../common.scss";

.knowmore_sec1 {
  background-color: #30a389;
  border-radius: 8px;
  width: 100%;
  max-width: 13.75em;
  // padding: 1.48em 1.970625em 1.459375em 2em;

  cursor: pointer;
  &:hover {
    background-image: linear-gradient(to right, #144c4b, #30a389);
    transition: 0.3s ease;
  }
  .knowmore_text {
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.25em;
    font-weight: 400;
    font-family: "Montserrat";
    letter-spacing: 0px;
    line-height: 1.2;
    // width: 100%;
    // max-width: 100%;
    padding: 1em 1.1em 1em 1.1em;
  }
  .knowmore_arrow {
    width: 100%;
    // max-width: 1.279375em;
    max-width: 1.0235em;
    margin-left: 1.1em;
  }
}

@media (max-width: 767px) {
  .knowmore_sec1 {
    border-radius: 6px;
    max-width: 14.5em;
    .knowmore_text {
      font-size: 1.4em;
      line-height: 1.285;
      // padding: 0.9285714285714286em  1.428571428571429em;
      padding: 0.9285714285714286em 1.1em;
    }
    .knowmore_arrow {
      max-width: 1.1em;
      margin-left: 0.8572em;
    }
  }
}
