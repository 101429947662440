@import "../../common.scss";

.coming_sec1 {
  position: relative;
  display: block;
  overflow: hidden;
  .coming_desktop {
    margin-top: .5em;
    width: 100%;
    max-width: 100%;
    max-height: 70vh;
  }
}

@media (max-width: 992px) {
  .coming_sec1 {
    .coming_desktop {
      object-fit: contain;
    }
  }
}
@media (max-width: 600px) {
  .coming_sec1 {
    overflow: auto;
    .my_container {
      padding: 0;
    }
    .img_wrapper {
      display: flex;
      justify-content: flex-start;
      max-height: 100%;
      padding-bottom: 25vh;
      .coming_desktop {
        margin-top: 0;
        max-height: 100%;
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
      }
    }
  }
}
