@import "../../common.scss";

.header {
  .new_logo_wrapper {
    position: relative;
    padding: 4.169em 0 0;
    .new_kapindra_logo {
      width: 100%;
      max-width: 25.813em;
    }
  }
}

@media (max-width: 600px) {
  .header {
    .new_logo_wrapper {
      padding: 2em 0;
    }
  }
}
